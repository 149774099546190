import React, { createContext, useState, useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { load } from '../pages/fleet/home/actions';
import { useDispatch } from 'react-redux';
// MSAL Integration
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "../configuration";
import moment from 'moment';

import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/nl';
import 'moment/locale/fo';//no
import 'moment/locale/pl';
import 'moment/locale/ru';
import 'moment/locale/sv';


const UserContext = createContext();


export const UserProvider = ({ children, languages }) => {

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    var runMode = process.env.REACT_APP_runMode?.includes('kvg') ? 'MyKverneland' : 'MyVicon'
    const [user, setUser] = useState({
        hasAccessToken: false,
        accessToken: '',
        signIn: (lang) => {
            sessionStorage.clear();
            instance.loginRedirect({ ...loginRequest, extraQueryParameters: { ui_locales: lang, signUpSource: runMode } }).catch(e => {
                console.error(e);
            });
        },
        editProfile: (lang) => {
            sessionStorage.clear();
            instance.loginRedirect({ ...loginRequest, extraQueryParameters: { ui_locales: lang, signUpSource: runMode, p: msalConfig.auth.policyEditProfile } })
        },
        terrakoEditProfile: (lang) => {
            sessionStorage.clear();
            sessionStorage["isterrakoEditProfile"] = "Clicked";
            instance.loginRedirect({ ...loginRequest, extraQueryParameters: { ui_locales: lang, signUpSource: runMode, p: msalConfig.auth.policyTerrakoEditProfile } })
        },

        editPassword: (lang) => {
            sessionStorage.clear();
            instance.loginRedirect({ ...loginRequest, extraQueryParameters: { ui_locales: lang, signUpSource: runMode, p: msalConfig.auth.policyEditPassword } })
        },
        editEmail: (lang) => {
            sessionStorage.clear();
            console.log(instance);
            debugger;
            instance.config.auth.redirectUri = msalConfig.auth.redirectUri + "logout";
            instance.loginRedirect({ ...loginRequest, extraQueryParameters: { ui_locales: lang, signUpSource: runMode, p: msalConfig.auth.policyEditEmail } })           
        },

        signUp: (lang) => {
            sessionStorage.clear();
            instance.loginRedirect({ ...loginRequest, extraQueryParameters: { ui_locales: lang, signUpSource: runMode, p: msalConfig.auth.policySignUp } })
        },
        logout: () => {
            sessionStorage.clear();
            instance.logoutRedirect().catch(e => {
                console.error(e);
            });
            logOut();
            window.location.href = window.location.href.replace('/logout/', '/').replace('/logout', '/');
        }
    });

    var timeoutInMiliseconds = 3600000;
    var timeoutId;
    function startTimer() {
        // window.setTimeout returns an Id that can be used to start and stop a timer
        timeoutId = window.setTimeout(doInactive, timeoutInMiliseconds)
    }
    function resetTimer() {
        window.clearTimeout(timeoutId)
        startTimer();
    }
    function doInactive() {
        if (isAuthenticated) {
            user.logout();
        }
    }
    function setupTimers() {
        document.addEventListener("mousemove", resetTimer, false);
        document.addEventListener("mousedown", resetTimer, false);
        document.addEventListener("keypress", resetTimer, false);
        document.addEventListener("touchmove", resetTimer, false);
        startTimer();
    }
    setupTimers();

    const dispatch = useDispatch();
    const loadFleetTable = (bearerToken, lang) => {
        dispatch(load(bearerToken, lang));
    }

    function acquireToken(request) {
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setUser({ ...user, accessToken: response.accessToken, hasAccessToken: true, expiresOn: response.expiresOn });

        }).catch((e) => {
            console.log(e);
        });
    }

    function RequestAccessToken() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        acquireToken(request);

        setInterval(() => {

            acquireToken(request);

        }, 120000);
    }

    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [isLogedIn, setIslogedIn] = useState(false);
    const [fleetProduct, setFleetProduct] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loadingCard, setLoadingCard] = useState(false);

    useEffect(() => {
        const urlLang = window.location.pathname.split('/')[1];

        if (!localStorage.getItem('lang')) {
            setShowModal(true);
        }
        if (localStorage.getItem('lang') && (localStorage.getItem('lang') !== urlLang) && languages.filter(item => item === urlLang)[0]) {
            setLanguageLocal(urlLang);
        }
    }, [])

    function setLanguageLocal(lang) {
        localStorage.setItem('lang', lang);
        window.location.href = '/' + lang + '/my-account'
    }
    let now = new Date();
    useEffect(() => {
        if(now > user.expiresOn) {
            user.logout();
        } else if (isAuthenticated && accounts[0]) {
            setIslogedIn(true);
            RequestAccessToken();
        }
    }, [isAuthenticated, instance, accounts, inProgress]);

    function logOut() {
        localStorage.removeItem('isLogedIn');
        localStorage.removeItem('accessToken');
        setIslogedIn(false);
    }

    const [notifications, setNotifications] = useState({
        unreadCount: Int16Array,
        notifications: []
    });
    const [selectedNotification, setSelectedNotification] = useState(0);
    function loadNotifications(){
        let accountID = accounts?.map(o=> o.localAccountId)?.pop();
        if(accountID) {
            var myHeaders = new Headers();
            myHeaders.append("X-API-KEY", "Murat");

            var runMode = process.env.REACT_APP_runMode?.includes('kvg') ? 'MyKverneland' : 'MyVicon'
            fetch(`https://mykvg-notif-dev-app.azurewebsites.net/api/v1/Notification/getAll?userId=${accountID}&Lang=${localStorage.getItem('lang')}&Brand=${runMode}`, {
                method: 'GET',    
                headers: myHeaders
            })
            .then(response => response.text())
            .then(response => {
                var notificationResult = JSON.parse(response);

                var lang = "";
                if(localStorage.getItem('lang')) { localStorage.getItem('lang').split('-').pop(); }
                if(!lang || lang == "") { lang = "en"; }

                if(lang == "no") { lang = "fo"; }
                moment.locale(lang)

                for (let index = 0; index < notificationResult.notifications.length; index++) {
                    var notification = notificationResult.notifications[index];

                    var createdDate = moment(notification["createdDate"]);
                    var elapsedDays = moment().diff(createdDate, 'days');
                    
                    if(elapsedDays < 10) { notification["DisplyTime"] = createdDate.fromNow(); }
                    else{  notification["DisplyTime"] = createdDate.format("YYYY-MM-DD"); }
                }
                setNotifications(notificationResult);
            })
            .catch(error => console.log('error', error));
        }
    }
    useEffect(() => {
        loadNotifications();
    }, [accounts, selectedNotification]);
    
    return (
        <UserContext.Provider value={{ user, accounts, RequestAccessToken, isLogedIn, logOut, selectedLanguage, setSelectedLanguage, setLanguageLocal, fleetProduct, setFleetProduct, showModal, loadFleetTable, loadingCard, setLoadingCard, notifications, selectedNotification, setSelectedNotification }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;